import { useMemo } from 'react';
import { useHash } from '../../useHash';

export function useCurrentModal<T extends Record<string, string>>(
  modalKeys: T,
): string | undefined {
  const modals: string[] = useMemo(() => Object.values(modalKeys), [modalKeys]);
  const modalUrl: string = useHash();

  const modal: string | undefined = useMemo(
    () => modals.find(modal => modal == modalUrl?.replaceAll('#', '')),
    [modalUrl, modals],
  );

  return modal;
}
