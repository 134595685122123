import { useCallback } from 'react';
import { useSearchParams } from '../../useSearchParams';
import { usePathname } from '../../usePathname';
import { NavigateFunction } from '@starsoft/common/interfaces/navigate-function';
import { useNavigate } from '../../useNavigate';

export function useModalNavigation() {
  const searchParams: URLSearchParams = useSearchParams();
  const navigate: NavigateFunction = useNavigate();
  const pathname: string = usePathname();

  const getModalLink = useCallback(
    (modal: string, params?: string): string => {
      let updatedSearchParams = new URLSearchParams(searchParams);

      if (pathname.includes('sports')) {
        const slug = updatedSearchParams.get('slug');

        if (!slug || slug.includes('...')) {
          updatedSearchParams.set('slug', 'live');
        }

        if (params) {
          const paramsObj = new URLSearchParams(params);

          if (!paramsObj.has('slug')) {
            paramsObj.set('slug', 'live');
            params = paramsObj.toString();
          }
        } else {
          updatedSearchParams.set('slug', 'live');
        }
      }

      const currentQueryParams: string = updatedSearchParams.toString()
        ? `?${updatedSearchParams.toString()}`
        : '';

      return `${pathname?.replace('[...slug]', searchParams.get('slug'))}${params ? `${params?.includes('?') ? params : `?${params}`}` : currentQueryParams}#${modal}`;
    },
    [pathname, searchParams],
  );

  const navigateToModal = useCallback(
    (modal: string, params?: string) => {
      const url: string = getModalLink(modal, params);
      navigate(url, { preventScrollReset: true });
    },
    [navigate, getModalLink],
  );

  return {
    getModalLink,
    navigateToModal,
  };
}
