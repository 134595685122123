import { useQuery, useQueryClient } from '@tanstack/react-query';
import { UserQueryKeys } from '../../queryKeys';
import { UseUserAccountProps } from './props';
import { User } from '@/models/user';
import { AxiosError } from 'axios';
import { GenericError } from '@/models/generic-error';
import { fetchUserProfile } from './service';
import { useEffect } from 'react';
import { useLogoutCallback } from '@/hooks/useLogoutCallback';
import { useAccountCallback } from '@/hooks/useAccountCallback';
import { getCookie } from 'cookies-next';
import { CookiesKey } from '@/enums/cookiesKey';
import { Either } from '@starsoft/common/interfaces';

export function useUserAccount(options?: UseUserAccountProps) {
  const queryClient = useQueryClient();
  const logout = useLogoutCallback();
  const callback = useAccountCallback();
  const { enabled = true, refetchOnWindowFocus = true } = options ?? {};
  const initialData = queryClient.getQueryData([
    UserQueryKeys.Profile,
  ]) as Either<User, undefined>;
  const isAuthorized =
    getCookie(CookiesKey.IsAuthorized) == 'true' || !!initialData;

  const {
    data: user,
    isPending,
    isError,
    error,
    refetch,
    isFetching,
    fetchStatus,
  } = useQuery<User, AxiosError<GenericError>>({
    queryKey: [UserQueryKeys.Profile],
    queryFn: fetchUserProfile,
    enabled: enabled && isAuthorized,
    staleTime: Infinity,
    refetchOnReconnect: 'always',
    refetchOnWindowFocus,
  });

  function onSuccess() {
    if (!user) {
      return;
    }

    callback();
  }

  useEffect(onSuccess, [user, callback]);

  function onError() {
    if (!error) {
      return;
    }

    logout();
  }

  useEffect(onError, [error, logout]);

  return {
    user,
    isAuthorized,
    isLoading: isPending && fetchStatus !== 'idle',
    isFetching,
    isError,
    error,
    refetch,
  };
}
