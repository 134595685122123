import { CoinID } from '@starsoft/common/enums';
import { CoinMetadata } from './interfaces';
import { CoinSymbolPosition } from './enums';

export const coinsMetadata: Record<CoinID, CoinMetadata> = {
  [CoinID.USD]: {
    decimalSeparator: '.',
    thousandSeparator: ',',
    decimalPlaces: 2,
    symbol: '$',
    symbolPosition: CoinSymbolPosition.Before,
  },
  [CoinID.EUR]: {
    decimalSeparator: ',',
    thousandSeparator: '.',
    decimalPlaces: 2,
    symbol: '€',
    symbolPosition: CoinSymbolPosition.Before,
  },
  [CoinID.CAD]: {
    decimalSeparator: '.',
    thousandSeparator: ',',
    decimalPlaces: 2,
    symbol: 'CA$',
    symbolPosition: CoinSymbolPosition.Before,
  },
  [CoinID.BRL]: {
    decimalSeparator: ',',
    thousandSeparator: '.',
    decimalPlaces: 2,
    symbol: 'R$',
    symbolPosition: CoinSymbolPosition.Before,
  },
  [CoinID.JPY]: {
    decimalSeparator: '',
    thousandSeparator: ',',
    decimalPlaces: 0,
    symbol: '¥',
    symbolPosition: CoinSymbolPosition.Before,
  },
  [CoinID.ARS]: {
    decimalSeparator: ',',
    thousandSeparator: '.',
    decimalPlaces: 2,
    symbol: 'AR$',
    symbolPosition: CoinSymbolPosition.Before,
  },
  [CoinID.CNY]: {
    decimalSeparator: '.',
    thousandSeparator: ',',
    decimalPlaces: 2,
    symbol: '¥',
    symbolPosition: CoinSymbolPosition.Before,
  },
  [CoinID.INR]: {
    decimalSeparator: '.',
    thousandSeparator: ',',
    decimalPlaces: 2,
    symbol: '₹',
    symbolPosition: CoinSymbolPosition.Before,
  },
  [CoinID.IDR]: {
    decimalSeparator: ',',
    thousandSeparator: '.',
    decimalPlaces: 0,
    symbol: 'Rp',
    symbolPosition: CoinSymbolPosition.Before,
  },
  [CoinID.KRW]: {
    decimalSeparator: '',
    thousandSeparator: ',',
    decimalPlaces: 0,
    symbol: '₩',
    symbolPosition: CoinSymbolPosition.Before,
  },
  [CoinID.CHF]: {
    decimalSeparator: '.',
    thousandSeparator: `'`,
    decimalPlaces: 2,
    symbol: 'CHF',
    symbolPosition: CoinSymbolPosition.After,
  },
  [CoinID.MXN]: {
    decimalSeparator: '.',
    thousandSeparator: ',',
    decimalPlaces: 2,
    symbol: 'MX$',
    symbolPosition: CoinSymbolPosition.Before,
  },
  [CoinID.DKK]: {
    decimalSeparator: ',',
    thousandSeparator: '.',
    decimalPlaces: 2,
    symbol: 'kr',
    symbolPosition: CoinSymbolPosition.After,
  },
  [CoinID.NZD]: {
    decimalSeparator: '.',
    thousandSeparator: ',',
    decimalPlaces: 2,
    symbol: 'NZ$',
    symbolPosition: CoinSymbolPosition.Before,
  },
  [CoinID.PHP]: {
    decimalSeparator: '.',
    thousandSeparator: ',',
    decimalPlaces: 2,
    symbol: '₱',
    symbolPosition: CoinSymbolPosition.Before,
  },
  [CoinID.RUB]: {
    decimalSeparator: ',',
    thousandSeparator: ' ',
    decimalPlaces: 2,
    symbol: '₽',
    symbolPosition: CoinSymbolPosition.After,
  },
  [CoinID.PEN]: {
    decimalSeparator: '.',
    thousandSeparator: ',',
    decimalPlaces: 2,
    symbol: 'S/',
    symbolPosition: CoinSymbolPosition.Before,
  },
  [CoinID.AUD]: {
    decimalSeparator: '.',
    thousandSeparator: ',',
    decimalPlaces: 2,
    symbol: 'A$',
    symbolPosition: CoinSymbolPosition.Before,
  },
  [CoinID.PLN]: {
    decimalSeparator: ',',
    thousandSeparator: ' ',
    decimalPlaces: 2,
    symbol: 'zł',
    symbolPosition: CoinSymbolPosition.After,
  },
  [CoinID.VND]: {
    decimalSeparator: ',',
    thousandSeparator: '.',
    decimalPlaces: 0,
    symbol: '₫',
    symbolPosition: CoinSymbolPosition.After,
  },
  [CoinID.TRY]: {
    decimalSeparator: ',',
    thousandSeparator: '.',
    decimalPlaces: 2,
    symbol: '₺',
    symbolPosition: CoinSymbolPosition.Before,
  },
  [CoinID.COP]: {
    decimalSeparator: ',',
    thousandSeparator: '.',
    decimalPlaces: 2,
    symbol: 'COP',
    symbolPosition: CoinSymbolPosition.After,
  },
  [CoinID.CLP]: {
    decimalSeparator: ',',
    thousandSeparator: '.',
    decimalPlaces: 0,
    symbol: 'CLP$',
    symbolPosition: CoinSymbolPosition.Before,
  },
  [CoinID.BTC]: {
    decimalSeparator: '.',
    thousandSeparator: ',',
    decimalPlaces: 8,
    symbol: '₿',
    symbolPosition: CoinSymbolPosition.Before,
  },
  [CoinID.ETH]: {
    decimalSeparator: '.',
    thousandSeparator: ',',
    decimalPlaces: 8,
    symbol: 'Ξ',
    symbolPosition: CoinSymbolPosition.Before,
  },
  [CoinID.USDT]: {
    decimalSeparator: '.',
    thousandSeparator: ',',
    decimalPlaces: 2,
    symbol: 'USDT',
    symbolPosition: CoinSymbolPosition.After,
  },
  [CoinID.FDUSD]: {
    decimalSeparator: '.',
    thousandSeparator: ',',
    decimalPlaces: 2,
    symbol: 'FDUSD',
    symbolPosition: CoinSymbolPosition.After,
  },
  [CoinID.USDC]: {
    decimalSeparator: '.',
    thousandSeparator: ',',
    decimalPlaces: 2,
    symbol: 'USDC',
    symbolPosition: CoinSymbolPosition.After,
  },
  [CoinID.BNB]: {
    decimalSeparator: '.',
    thousandSeparator: ',',
    decimalPlaces: 8,
    symbol: 'BNB',
    symbolPosition: CoinSymbolPosition.After,
  },
  [CoinID.SHIB]: {
    decimalSeparator: '.',
    thousandSeparator: ',',
    decimalPlaces: 8,
    symbol: 'SHIB',
    symbolPosition: CoinSymbolPosition.After,
  },
  [CoinID.DOGE]: {
    decimalSeparator: '.',
    thousandSeparator: ',',
    decimalPlaces: 8,
    symbol: 'DOGE',
    symbolPosition: CoinSymbolPosition.After,
  },
  [CoinID.XRP]: {
    decimalSeparator: '.',
    thousandSeparator: ',',
    decimalPlaces: 6,
    symbol: 'XRP',
    symbolPosition: CoinSymbolPosition.After,
  },
  [CoinID.POL]: {
    decimalSeparator: '.',
    thousandSeparator: ',',
    decimalPlaces: 8,
    symbol: 'POL',
    symbolPosition: CoinSymbolPosition.After,
  },
  [CoinID.TRX]: {
    decimalSeparator: '.',
    thousandSeparator: ',',
    decimalPlaces: 6,
    symbol: 'TRX',
    symbolPosition: CoinSymbolPosition.After,
  },
  [CoinID.SOL]: {
    decimalSeparator: '.',
    thousandSeparator: ',',
    decimalPlaces: 6,
    symbol: 'SOL',
    symbolPosition: CoinSymbolPosition.After,
  },
  [CoinID.AXS]: {
    decimalSeparator: '.',
    thousandSeparator: ',',
    decimalPlaces: 6,
    symbol: 'AXS',
    symbolPosition: CoinSymbolPosition.After,
  },
  [CoinID.TON]: {
    decimalSeparator: '.',
    thousandSeparator: ',',
    decimalPlaces: 6,
    symbol: 'TON',
    symbolPosition: CoinSymbolPosition.After,
  },
};

export const coinsMetadataMapping: Map<CoinID, CoinMetadata> = new Map(
  Object.entries(coinsMetadata).map(([key, value]) => [
    Number(key) as CoinID,
    value,
  ]),
);
