import {
  AuthStateProps,
  AuthActions,
  PasswordRecoverSelectProps,
  PasswordRecoverHandleInputProps,
  AuthSetCurrentCoinSuccessPayload,
} from './props';

const initialState: AuthStateProps = {
  loading: false,
  error: false,
  smsSendAt: null,
  emailSendAt: null,
  option: 'email',
  optionValue: '',
  currentCoin: null,
  recoverEmail: null,
  dehydratedState: null,
};

export default function reducer(
  state: AuthStateProps = initialState,
  action: any,
): AuthStateProps {
  switch (action.type) {
    case AuthActions.SendSms:
      return {
        ...state,
        smsSendAt: new Date(+new Date() + 60000 * 1), // 1 minuto
      };
    case AuthActions.RequestResetPassword:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case AuthActions.SendEmail:
      return {
        ...state,
        emailSendAt: new Date(+new Date() + 60000 * 1),
      };
    case AuthActions.SetRecoverOption:
      return {
        ...state,
        loading: false,
        option: (action as PasswordRecoverSelectProps).payload.option,
      };
    case AuthActions.SetRecoverValue:
      return {
        ...state,
        loading: false,
        optionValue: (action as PasswordRecoverHandleInputProps).payload
          .optionValue,
      };
    case AuthActions.SetCurrentCoin:
      return {
        ...state,
        currentCoin: (action as AuthSetCurrentCoinSuccessPayload).payload.coin,
      };
    case AuthActions.SetRecoverEmail:
      return {
        ...state,
        recoverEmail: action.payload,
      };
    case AuthActions.DehydratedState:
      return {
        ...state,
        dehydratedState: action.payload,
      };
    default:
      return state;
  }
}
