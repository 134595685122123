import { GameTagFilter } from '@/models/games/game-tag-filter.enum';
import { PageProps } from '@/types/page/props';

export default <PageProps[]>[
  {
    label: 'recently_played',
    paths: [`/games/recently-played`],
    icon: `fa-solid fa-rotate-left`,
    isProtected: true,
  },
  {
    label: 'originals_label',
    paths: [`/games/tag/${GameTagFilter.Originals}`],
    icon: `fa-solid fa-fire`,
  },
  {
    label: 'recommended_games',
    paths: [`/games/tag/${GameTagFilter.Recommended}`],
    icon: 'fa-solid fa-star',
  },
  {
    label: 'releases_label',
    paths: [`/games/tag/${GameTagFilter.NewReleases}`],
    icon: 'fa-solid fa-rocket',
  },
  {
    label: 'tab_live_casino',
    paths: [`/games/tag/${GameTagFilter.LiveCasino}`],
    icon: 'fa-solid fa-tv-retro fa-swap-opacity',
  },
  {
    label: 'tab_roulette',
    paths: [`/games/tag/${GameTagFilter.Roulette}`],
    icon: 'fa-sharp fa-solid fa-card-diamond',
  },
  {
    label: 'tab_fortune',
    paths: [`/games/tag/${GameTagFilter.Fortune}`],
    icon: `fa-solid fa-spade fa-swap-opacity`,
  },
  {
    label: 'tab_slots',
    paths: [`/games/tag/${GameTagFilter.Slots}`],
    icon: 'fa-solid fa-cherries fa-swap-opacity',
  },
  {
    label: 'enhanced_rtp',
    icon: 'fa-solid fa-arrow-trend-up',
    paths: [`/games/tag/${GameTagFilter.EnhancedRTP}`],
  },
  {
    label: 'tab_poker',
    icon: 'fa-solid fa-club',
    paths: [`/games/tag/${GameTagFilter.Poker}`],
  },
  {
    label: 'tab_blackjack',
    icon: 'fa-solid fa-card-spade',
    paths: [`/games/tag/${GameTagFilter.Blackjack}`],
  },
  {
    label: 'tab_baccarat',
    icon: 'fa-solid fa-cards',
    paths: [`/games/tag/${GameTagFilter.Baccarat}`],
  },
  {
    label: 'tags_label',
    icon: 'fa-solid fa-tag',
    paths: [`/games/tag`],
  },
  {
    label: 'tab_providers',
    paths: ['/games/providers'],
    icon: `fa-solid fa-joystick`,
  },
];
