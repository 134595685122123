export type SideBarStateProps = {
  collapsed: boolean;
  open: boolean;
  clickedSportCollapsed: string[];
  showHeader: boolean;
};

export enum SideBarActions {
  Tab = 'sidebar/setTab',
  Collapse = 'sideBar/collapse',
  ToggleOpenSidebar = 'sidebar/toggle',
  SetClickedSportCollapsed = 'sidebar/setClickedSportCollpased',
  RemoveClickedSportCollapsed = 'sidebar/removeClickedSportCollapsed',
  ToggleHeader = 'sidebar/toggle-header',
}

export interface SidebarBasePayload {
  type: string;
}

export interface CollapseSideBarPayload extends SidebarBasePayload {
  payload: boolean;
}

export interface SetClickedSportCollapsedPayload extends SidebarBasePayload {
  payload: {
    sportSlug: string;
  };
}

export interface ToggleHeaderVisibilityPayload extends SidebarBasePayload {}

export type SideBarStatePayload =
  | CollapseSideBarPayload
  | ToggleHeaderVisibilityPayload
  | SetClickedSportCollapsedPayload;
