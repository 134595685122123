import { PropsWithChildren } from 'react';
import { CommonProvider } from '@starsoft/common/providers';
import useTranslation from 'next-translate/useTranslation';
import Trans from 'next-translate/Trans';
import TransText from 'next-translate/TransText';
import { useNotificationService } from '@/hooks/notifications/useNotificationService';
import { CommonSettings } from '@starsoft/common/interfaces';
import baseAPI from '@/api';
import { NextNavigate } from '@starsoft/common/components';
import {
  useNextHash,
  useNextNavigate,
  useNextPathname,
  useNextSearchParams,
} from '@starsoft/common/hooks';
import Link from '@/components/core/Link';

export default function PackageCommonSettingsProvider({
  children,
}: PropsWithChildren) {
  const settings: CommonSettings = {
    useTranslation: useTranslation,
    translationComponents: {
      Trans: Trans,
      TransText: TransText,
    },
    useNotificationService: useNotificationService,
    axiosInstance: baseAPI,
    router: {
      useNavigate: useNextNavigate,
      usePathname: useNextPathname,
      useSearchParams: useNextSearchParams,
      useHash: useNextHash,
      Navigate: NextNavigate,
      Link,
    },
  };

  return <CommonProvider settings={settings}>{children}</CommonProvider>;
}
