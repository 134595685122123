import React from 'react';
import { TooltipComponentsProps } from './props';
import styles from './styles.module.scss';
import { Tooltip as MuiTooltip } from '@mui/material';
import { getComputedColor } from '@starsoft/common/utils';
import Color from 'color';

export function ToolTip({
  children,
  titleComponent,
  visible = true,
  invertColor,
}: TooltipComponentsProps) {
  return (
    (visible && (
      <MuiTooltip
        followCursor
        sx={{
          background: 'unset',
          padding: 'unset',
          backgroundColor: getComputedColor(
            !invertColor ? '--card-color' : '--background-paper-color',
          ),
          color: getComputedColor('--text-primary-color'),
          borderRadius: '8px',
          border: `1px solid ${Color(
            getComputedColor('--text-secondary-color').trim(),
          )
            .alpha(0.15)
            .toString()}`,
          maxWidth: 300,
          fontSize: '12px',
          fontFamily: 'Montserrat',
        }}
        title={<div className={styles.container}>{titleComponent}</div>}
      >
        {children}
      </MuiTooltip>
    )) ||
    children
  );
}
