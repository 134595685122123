import {
  CollapseSideBarPayload,
  SetClickedSportCollapsedPayload,
  SideBarActions,
  SideBarStatePayload,
  SideBarStateProps,
} from './props';

const initialState: SideBarStateProps = {
  collapsed: false,
  open: false,
  clickedSportCollapsed: [],
  showHeader: true,
};

export default function sideBarReducer(
  state: SideBarStateProps = initialState,
  action: SideBarStatePayload,
): SideBarStateProps {
  switch (action.type) {
    case SideBarActions.Collapse: {
      const collapse: boolean = (action as CollapseSideBarPayload).payload;
      return { ...state, ...{ collapsed: collapse } };
    }

    case SideBarActions.SetClickedSportCollapsed: {
      return {
        ...state,

        clickedSportCollapsed: state.clickedSportCollapsed.concat(
          (action as SetClickedSportCollapsedPayload).payload.sportSlug,
        ),
      };
    }

    case SideBarActions.RemoveClickedSportCollapsed: {
      return {
        ...state,
        clickedSportCollapsed: state.clickedSportCollapsed.filter(
          sportSlug =>
            sportSlug !==
            (action as SetClickedSportCollapsedPayload).payload.sportSlug,
        ),
      };
    }

    case SideBarActions.ToggleOpenSidebar: {
      return {
        ...state,
        open: !state.open,
      };
    }

    case SideBarActions.ToggleHeader:
      return {
        ...state,
        showHeader: !state.showHeader,
      };
    default: {
      return state;
    }
  }
}
