import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ErrorService } from '@/services/ErrorService';
import { AxiosError } from 'axios';
import { GenericError } from '@/models/generic-error';
import { PropsWithChildren, useState } from 'react';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';

function makeQueryClient() {
  return new QueryClient({
    defaultOptions: {
      mutations: {
        onError(error) {
          ErrorService.onError(error as AxiosError<GenericError>);
        },
        retry(failureCount: number, error: Error) {
          if (failureCount >= 3) {
            return false;
          }

          return (
            (error as AxiosError<GenericError>)?.response?.data?.statusCode ==
              502 || (error as AxiosError<GenericError>)?.status == 502
          );
        },
      },
    },
  });
}

let browserQueryClient: QueryClient | undefined = undefined;

export function getQueryClient() {
  if (typeof window === 'undefined') {
    return makeQueryClient();
  } else {
    if (!browserQueryClient) browserQueryClient = makeQueryClient();
    return browserQueryClient;
  }
}

export default function TanstackQueryProvider({ children }: PropsWithChildren) {
  const [queryClient] = useState(() => getQueryClient());

  return (
    <QueryClientProvider client={queryClient}>
      {children}
      <ReactQueryDevtools buttonPosition="bottom-left" />
    </QueryClientProvider>
  );
}
