import { setGameMode } from '@/lib/store/casino/actions';
import { CasinoGameMode } from '@/lib/store/casino/props';
import { AuthService } from '@/services/AuthService';
import { LoginByEmailSuccessOutputDto } from '@/services/dto/auth/auth-login-response.dto';
import { useQueryClient } from '@tanstack/react-query';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useRouter } from 'next/router';
import { usePathname } from './usePathname';

export function useLoginCallback() {
  const queryClient = useQueryClient();
  const { prefetch, replace } = useRouter();
  const pathname: string = usePathname();
  const dispatch = useDispatch();

  const callback = useCallback(
    (data: LoginByEmailSuccessOutputDto, path = pathname) => {
      AuthService.setAsAuthorized(data);
      queryClient.removeQueries();
      queryClient.invalidateQueries();
      dispatch(setGameMode(CasinoGameMode.Real));
      prefetch(path);
      replace(path?.includes('logout') ? '/' : path);
    },
    [dispatch, pathname, queryClient, prefetch, replace],
  );

  return callback;
}
