export enum LocalStorageKey {
  GamesMuted = 'games-muted',
  GameMode = 'game-mode',
  HasSeenFollowBetTutorial = 'has-seen-follow-bet-tutorial',
  SearchHistory = 'search-history',
  ChartType = 'chart/type',
  ChartInterval = 'chart/interval',
  ChartActiveIndicators = 'chart/indicators/active',
  ConversionCoinId = 'conversion-coin-id ',
  ConvertToCoin = 'convert-to-coin ',
  HiddenSmalls = 'hidden-smalls',
  TowerGrid = 'tower-grid',
  SlotConversionCoin = 'slot-conversion-coin',
}
