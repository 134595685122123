import { Coin } from '@/models/coin';
import { GenericError } from '@/models/generic-error';
import { Nullable } from '@starsoft/common/interfaces';
import { DehydratedState } from '@tanstack/react-query';

export enum AuthActions {
  SendSms = 'auth/sendSms',
  RequestResetPassword = 'auth/passwordRecoverRequest',
  SendEmail = 'passwordRecover/sendEmail',
  SetRecoverOption = 'auth/passwordRecoverSetRecoverOption',
  SetRecoverValue = 'auth/passwordRecoverSetRecoverValue',
  SetCurrentCoin = 'auth/setCurrentCoin',
  SetRecoverEmail = 'auth/setRecoverEmail',
  DehydratedState = 'auth/dehydratedState',
}

export interface AuthStateProps {
  loading: boolean;
  error: GenericError | false;
  smsSendAt: null | Date;
  emailSendAt: null | Date;
  option: string;
  optionValue: string;
  currentCoin: Coin | null;
  recoverEmail: Nullable<string>;
  dehydratedState: Nullable<DehydratedState>;
}

export interface AuthStateBasePayload {
  type: string;
}

export interface AuthSetCurrentCoinSuccessPayload extends AuthStateBasePayload {
  payload: {
    coin: Coin;
  };
}

export type AuthSendEmailPayload = AuthStateBasePayload;
export type AuthRequestSendSmsPayload = AuthStateBasePayload;

export interface PasswordRecoverSelectProps extends AuthStateBasePayload {
  payload: {
    option: string;
  };
}

export interface PasswordRecoverHandleInputProps extends AuthStateBasePayload {
  payload: {
    optionValue: string;
  };
}

export interface SetRecoverEmailPayload extends AuthStateBasePayload {
  payload: string;
}

export interface SetDehydratedStatePayload extends AuthStateBasePayload {
  payload: DehydratedState;
}

export type PasswordRecoverResetRequest = AuthStateBasePayload;

export type AuthStatePayload =
  | PasswordRecoverSelectProps
  | PasswordRecoverResetRequest
  | PasswordRecoverHandleInputProps
  | AuthRequestSendSmsPayload;
