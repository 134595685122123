import { QueryFunctionContext } from '@tanstack/react-query';
import { UseUserEmailsQueryKeyProps } from './props';
import { PaginatedResponse, UserEmail } from '@starsoft/common/models';
import { AxiosInstance } from 'axios';

export async function fetchUserEmails(
  { queryKey }: QueryFunctionContext<UseUserEmailsQueryKeyProps>,
  axiosInstance: AxiosInstance,
) {
  const { limit, page } = queryKey[1];
  const response = await axiosInstance.get<PaginatedResponse<UserEmail>>(
    `/user-emails/me`,
    {
      params: {
        page,
        limit,
      },
    },
  );

  return response.data;
}
