import { useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { WalletsQueryKeys } from '../../queryKeys';
import { GenericError } from '@/models/generic-error';
import { UseWalletsMeProps } from './props';
import { Wallet } from '@/models/wallet';
import { fetchWalletsMe } from './service';
import { AuthService } from '@/services/AuthService';
import { useCallback, useMemo } from 'react';
import { blockedSportsCoins } from '@/components/Sportsbook/options';
import { Coin, CoinType } from '@starsoft/common/models';

export function useWalletsMe(options?: UseWalletsMeProps) {
  const {
    enabled = true,
    refetchOnMount = true,
    refetchOnWindowFocus = true,
  } = options ?? {};
  const queryKey = [WalletsQueryKeys.Me];

  const { isPending, error, data, refetch, fetchStatus, isRefetching } =
    useQuery<Wallet, AxiosError<GenericError>, Wallet, typeof queryKey>({
      queryKey,
      queryFn: fetchWalletsMe,
      enabled: enabled && AuthService.isAuthorized(),
      staleTime: Infinity,
      refetchOnMount,
      refetchOnWindowFocus,
    });

  const isCurrentAccountSportsbookUnavailable: boolean = useMemo(
    () =>
      blockedSportsCoins.some(
        blockedCoin => data?.currentAccount?.coin?.id == blockedCoin,
      ),
    [data?.currentAccount?.coin?.id],
  );

  const getCoinsByType = useCallback(
    (type: CoinType) => {
      return data?.accounts
        ?.filter(account => account?.coin?.type == type)
        ?.map(account => account?.coin);
    },
    [data],
  );

  const cryptos: Coin[] = useMemo(
    () => getCoinsByType(CoinType.Crypto),
    [getCoinsByType],
  );
  const fiats: Coin[] = useMemo(
    () => getCoinsByType(CoinType.Fiat),
    [getCoinsByType],
  );

  return {
    isLoading: isPending && fetchStatus !== 'idle',
    error: error,
    wallet: data,
    refetch,
    isRefetching,
    isCurrentAccountSportsbookUnavailable,
    cryptos,
    fiats,
  };
}
