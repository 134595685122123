import { useQuery } from '@tanstack/react-query';
import { fetchUserEmails } from './service';
import { UserEmailsQueryKeys } from '../../queryKeys';
import { AxiosError, AxiosInstance } from 'axios';
import { UseUserEmailsProps, UseUserEmailsQueryKeyProps } from './props';
import { useEffect, useMemo, useState } from 'react';
import { useAxiosInstance, usePaginatedQuery } from '@starsoft/common/hooks';
import {
  GenericError,
  PaginatedRequest,
  PaginatedResponse,
  UserEmail,
} from '@starsoft/common/models';
import { AuthService } from '@starsoft/common/services';
import { setCookie } from 'cookies-next';
import { CookiesKey } from '@starsoft/common/enums';

export function useUserEmails(options?: UseUserEmailsProps) {
  const { externalOnSuccess } = options ?? {};
  const [page, setPage] = useState<number>(1);
  const params: PaginatedRequest = {
    page,
    limit: 50,
  };
  const axiosInstance: AxiosInstance = useAxiosInstance();

  const queryKey: UseUserEmailsQueryKeyProps = [UserEmailsQueryKeys.Me, params];

  const { data, isPending, isError, error, refetch, fetchStatus } = useQuery<
    PaginatedResponse<UserEmail>,
    AxiosError<GenericError>,
    PaginatedResponse<UserEmail>,
    UseUserEmailsQueryKeyProps
  >({
    queryKey,
    queryFn: queryContext => fetchUserEmails(queryContext, axiosInstance),
    staleTime: Infinity,
    enabled: AuthService.isAuthorized(),
  });

  const primaryEmail: UserEmail | undefined = useMemo(
    () => data?.data?.find(email => email?.primary),
    [data],
  );

  const { fetchNextPage, fetchPreviousPage, setCurrentPage } =
    usePaginatedQuery({
      page,
      setPage,
      data,
    });

  function handleExternalOnSuccess() {
    if (!data || typeof externalOnSuccess == 'undefined') {
      return;
    }

    externalOnSuccess?.(data);
  }

  //eslint-disable-next-line
  useEffect(handleExternalOnSuccess, [data]);

  function handleUpdateUserEmailTrackCookie() {
    if (!primaryEmail) {
      return;
    }

    setCookie(CookiesKey.UserEmail, primaryEmail?.email, {
      maxAge: 90 * 24 * 60 * 60 * 1000, //90 days,
    });
  }

  useEffect(handleUpdateUserEmailTrackCookie, [primaryEmail]);

  return {
    page,
    error,
    refetch,
    isError,
    emails: data,
    primaryEmail,
    fetchNextPage,
    setCurrentPage,
    fetchPreviousPage,
    isLoading: isPending && fetchStatus != 'idle',
  };
}
