export enum CookiesKey {
  Lang = 'Lang',
  ClientRouter = 'clientRouter',
  IsAuthorized = 'IsAuthorized',
  Token = 'token',
  RefreshToken = 'refresh-token',
  Voucher = 'voucher',
  BetbyToken = 'betby-token',
  Register = 'register',
  CasinoLang = 'casino-lang',
  ByPass = 'bypass',
  HideBonusCardMobile = 'hide-bonus-card-mobile',
  LastUserBonusId = 'last-user-bonus-id',
  UserEmail = '_usermail',
}
